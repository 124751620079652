@import "../src/styles/variables";
@import "../node_modules/bootstrap/scss/bootstrap";

body {
  background-color: $background;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2, h3, p {
    color: $font-color;
  }
}

.card {
  border-radius: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  max-width: 80vw;
}

.btn {
  font-weight: 600;
  color: white;
  letter-spacing: .3vw;
  word-spacing: 1vw;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-fullHeight {
  height: 100vh;
}

.container-fullHeight-footer {
  min-height: 85vh;
  justify-content: space-around;
}

.container-small {
  padding: 0 5vw;
}

.height-35 {
  height: 35vh;
}