@import "../../styles/variables";

.footer {
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.2);

        .nav-link {
        font-size: 12px;
        
        &.active {
            font-weight: 600;
            color: $primary;
        }
    }
}