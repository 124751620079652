#interactive.viewport {
    width: 100vw;
    height: 92vh;
  }
  
  #interactive.viewport canvas,
  video {
    width: 100vw;
    height: 92vh;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  #interactive.viewport canvas.drawingBuffer,
  video.drawingBuffer {
    width: 100vw;
    height: 92vh;
  }